import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import placeholder from '../images/placeholder.png'; 

const TopArticles = ({ topArticlesData  }) => {

  const data = useStaticQuery(graphql`
    query {
      allWordpressWpBlog (filter: { wordpress_id: { in: [11120, 17382, 23541] } }) {
        edges {
          node {
            wordpress_id
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            modified(formatString: "MMMM DD, YYYY")
            acf {
              blog_grid_image {
                source_url
              }
              select_category
            }
          }
        }
      }
    }
  `);



const allPosts = topArticlesData;
  return (

 <>

        {allPosts.map((edge) => (
          <div key={edge.post_name} className="col-sm-6 col-12 col-lg-4 program-box-wrapper margin-30px-bottom">
            <div className="blog-post-content pb-60 wow fadeInUp br-15 h-100 blog-text overflow-hidden pb-0 bg-white" data-wow-duration="1s" data-wow-delay=".1s">
              <Link to={`/study-resources/${edge.post_name}/`} className="blog-image topnews border-0 programlink">
                <img src={edge.blog_grid_image_url  ? edge.blog_grid_image_url.source_url : placeholder} className="img-fluid" alt={edge.post_title} />
              </Link>
              <div className="d-inline-block w-100">
                <div className="conline-icon-Triangle-ArrowUpt padding-1-half-rem-all lg-padding-1-half-rem-all xs-padding-20px-lr xs-padding-40px-top xs-padding-10px-bottom position-relative mx-auto">
                  <div className="blog-details-overlap text-small font-weight-500 yellowbg border-radius-4px alt-font text-white text-uppercase programlink">
                    {edge.select_category}
                  </div>
                  <h6 className="alt-font font-weight-500 margin-10px-top margin-15px-bottom text-extra-dark-gray">
                    <Link to={`/study-resources/${edge.post_name}/`} className="text-extra-dark-gray programlink">
                      {edge.post_title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}
                    </Link>
                  </h6>
                  <small className="text-muted">
  {`Updated on ${edge.post_modified ? edge.post_modified : edge.post_date}`}
</small>

                </div>
              </div>
            </div>
          </div>
        ))}
</>
  );
};

export default TopArticles;